import React, { useCallback, useEffect, useState } from "react";
import { KeyboardEvent } from "react";
import List from "./components/list";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import gproducts from "./jsons/products.json";
import products from "./jsons/categoryProducts.json";
import categories from "./jsons/categories.json";
import bestCoupang from "./jsons/best_coupang.json";
import ListCategory from "./components/listCategory";
import ListCoupang from "./components/listCoupang";
import Display from "./displays";
import BottomNavigator from "./components/bottomNavigator";
import NaverLogin from "./login/naverLogin";
import Header from "./components/header";
import useDeviceDetection from "./hooks/useDeviceDetection";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import { Swiper as SwiperCore } from "swiper";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div>
          <Typography>{children}</Typography>
        </div>
      )}
    </div>
  );
}

function Home() {
  const sortedProduct = products.sort((a, b) => b.id - a.id);
  const [productLists, setProductLists] = useState(sortedProduct);
  const [value, setValue] = React.useState(0);
  const device = useDeviceDetection();

  //alert(device);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // 화면 크기 기준으로 모바일 여부 판단 (기본적으로 768px 이하를 모바일로 간주)
    const checkMobile = () => {
      if (window.innerWidth <= 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    // 처음 로드 시 체크
    checkMobile();

    // 리사이즈 이벤트에 따라 체크
    window.addEventListener("resize", checkMobile);

    // 컴포넌트 언마운트 시 리스너 제거
    return () => {
      window.removeEventListener("resize", checkMobile);
    };
  }, []);

  return (
    <>
      <div className="App">
        <Header className={isMobile ? "topHeaderMobile" : "topHeader"} />
        {/* <div className={isMobile ? "mobile-class" : "web-class"}>
          <h1>{isMobile ? "모바일 디바이스입니다" : "웹 브라우저입니다"}</h1>
        </div> */}
        <div className="swiperWrap">
          <div
            className={isMobile ? "swiper-container" : "swiper-container-web"}
          >
            <Swiper
              spaceBetween={50}
              onSlideChange={() => console.log("slide change")}
              onSwiper={(swiper: SwiperCore) => console.log(swiper)}
              loop={true} // 슬라이드가 끝나면 처음으로 돌아가도록 설정
              navigation={{
                nextEl: ".swiper-button-next", // '다음' 버튼을 정의
                prevEl: ".swiper-button-prev", // '이전' 버튼을 정의
              }}
            >
              <div className="swiper-button-next">
                <i className="fas fa-chevron-right"></i>
              </div>
              <div className="swiper-button-prev">
                <i className="fas fa-chevron-left"></i>
              </div>

              <SwiperSlide>
                <div className="TextSwiper">
                  코스트코에서 너무 대량 구매하셨나요?
                </div>
                <img
                  src="./img/pp1.jpeg"
                  alt="Slide 1"
                  className="imgExcluded"
                />
              </SwiperSlide>
              <SwiperSlide>
                <div className="TextSwiper">반띵 앱으로 나누어 보세요!</div>
                <img
                  src="./img/pp4.png"
                  alt="Slide 1"
                  className="imgExcluded"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div>

        {/* <div className="TextSwiper">코스트코에서 너무 대량 구매하셨나요?</div>
        <div className="descImageWrap">
          <img src="./img/pp1.jpeg" alt="Slide 1" className="descImage" />
        </div> */}
        <div className="InstallWrap">
          <div>
            <button
              className="InstallButton"
              onClick={() =>
                window.open(
                  "https://apps.apple.com/kr/app/%EB%B0%98%EB%9D%B5/id6740470773",
                  "_blank"
                )
              }
            >
              <img
                src="./img/apple-icon.png"
                alt="Install Icon"
                className="icon-ioc"
              />

              <span className="InstallButtonSpan">아이폰 앱 다운로드</span>
            </button>
            <button
              className="InstallButton"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=xyz.restocking.mobile&gl=KR",
                  "_blank"
                )
              }
            >
              <img
                src="./img/android-icon.png"
                alt="Install Icon"
                className="icon"
              />
              <span>안드로이드 앱 다운로드</span>
            </button>
          </div>
        </div>
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="코스트코 상품" {...a11yProps(1)} />
            <Tab
              label="쿠팡 베스트상품"
              {...a11yProps(0)}
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "center",
              }}
            />
            {/* <Tab label="코스트코 인기상품" {...a11yProps(2)} /> */}
          </Tabs>
          <CustomTabPanel value={value} index={0}>
            <div className="ProductPart">
              {categories.map(({ category_id: cid, title }) => {
                const plist = productLists.filter(
                  ({ category_id }) => category_id === cid
                );

                return (
                  <div className="topProducts">
                    <div className="titleSet">
                      <img src="./img/check-mark.png" width="30" height="30" />
                      {title}
                    </div>
                    <div className="setWrap">
                      <ListCategory list={plist} category_id={cid} count={5} />
                    </div>
                  </div>
                );
              })}
              <div>
                <div className="titleSet">쿠팡 코스트코 상품 모음</div>
                <iframe
                  src="https://ads-partners.coupang.com/widgets.html?id=761159&template=carousel&trackingCode=AF7382069&subId=&width=680&height=140&tsource="
                  width="300"
                  height="700"
                ></iframe>
              </div>
            </div>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <div className="topProducts">
              <div>
                <ListCoupang list={bestCoupang} />
              </div>
            </div>
          </CustomTabPanel>
          {/* <CustomTabPanel value={value} index={2}>
            <div className="topProducts">
              <div>
                <div className="titleSet">코스트코 인기 상품</div>
                <List list={gproducts} count={5} />
              </div>
              <div className="WrapProduct">
                <div className="titleSet">쿠팡 코스트코 상품 모음</div>
                <iframe
                  src="https://ads-partners.coupang.com/widgets.html?id=761159&template=carousel&trackingCode=AF7382069&subId=&width=680&height=140&tsource="
                  width="300"
                  height="700"
                ></iframe>
              </div>
            </div>
          </CustomTabPanel> */}
        </div>
      </div>
      <div className="WrapProduct">
        <iframe
          src="https://ads-partners.coupang.com/widgets.html?id=780875&template=carousel&trackingCode=AF7382069&subId=&width=680&height=140&tsource="
          width="100%"
          height="140"
        ></iframe>
      </div>
      <div className="titleCoupangPartner">
        "이 포스팅은 쿠팡 파트너스 활동의 일환으로, 이에 따른 일정액의 수수료를
        제공받습니다."
      </div>
      <div className="footer">email : sugyeongh460@gmail.com</div>
      {device !== "Mobile" ? (
        <div className="sticky">
          <BottomNavigator />
        </div>
      ) : null}
    </>
  );
}

export default Home;
