import React, { useCallback, useEffect, useState } from "react";
import { KeyboardEvent } from "react";
import List from "./components/list";
import RecentList from "./components/listCategory";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import products from "./jsons/products.json";
import Main from "./Main";
import Detail from "./detail";
import Home from "./Home";
import Front from "./Front";

import { Helmet } from "react-helmet";
import Login from "./login";
const RouteApp = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/" element={<Front />} />
         */}
        <Route path="/detail/:vendorId/:id/:productName" element={<Detail />} />
        <Route path="/detail/:vendorId/:id" element={<Detail />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
};
export default RouteApp;
